import { useState } from 'react';
import './Pace.css';

// Function to convert pace (minutes per km) to minutes per mile
const convertPaceToMile = (paceInKm) => {
    if (!paceInKm || !paceInKm.includes(':')) return '';  // Handle empty or invalid inputs

    const paceArray = paceInKm.split(':');
    if (paceArray.length !== 2) return '';  // Ensure we have exactly two parts: minutes and seconds

    const paceMinutes = parseInt(paceArray[0]);
    const paceSeconds = parseInt(paceArray[1]);

    // Check if the parsing works correctly
    if (isNaN(paceMinutes) || isNaN(paceSeconds)) return '';

    const paceInMinutes = paceMinutes + paceSeconds / 60;

    // 1 km = 0.621371 miles
    const paceInMiles = paceInMinutes / 0.621371;
    const milesMinutes = Math.floor(paceInMiles);
    const milesSeconds = Math.round((paceInMiles - milesMinutes) * 60);

    // Return the formatted time (mm:ss) for mile pace
    return `${milesMinutes}:${milesSeconds.toString().padStart(2, '0')}`;
};

// Function to calculate speed in kph
const calculateKph = (paceInKm) => {
    if (!paceInKm || !paceInKm.includes(':')) return '';  // Handle empty or invalid inputs

    const paceArray = paceInKm.split(':');
    if (paceArray.length !== 2) return '';  // Ensure we have exactly two parts: minutes and seconds

    const paceMinutes = parseInt(paceArray[0]);
    const paceSeconds = parseInt(paceArray[1]);

    // Check if the parsing works correctly
    if (isNaN(paceMinutes) || isNaN(paceSeconds)) return '';

    const paceInMinutes = paceMinutes + paceSeconds / 60;

    // Calculate speed in kph (60 / paceInMinutes)
    const kph = (60 / paceInMinutes).toFixed(2);
    return kph;
};

// Function to calculate speed in mph
const calculateMph = (paceInKm) => {
    if (!paceInKm || !paceInKm.includes(':')) return '';  // Handle empty or invalid inputs

    const paceArray = paceInKm.split(':');
    if (paceArray.length !== 2) return '';  // Ensure we have exactly two parts: minutes and seconds

    const paceMinutes = parseInt(paceArray[0]);
    const paceSeconds = parseInt(paceArray[1]);

    // Check if the parsing works correctly
    if (isNaN(paceMinutes) || isNaN(paceSeconds)) return '';

    const paceInMinutes = paceMinutes + paceSeconds / 60;

    // Calculate speed in mph (60 / (paceInMinutes / 0.621371))
    const mph = (60 / (paceInMinutes / 0.621371)).toFixed(2);
    return mph;
};

// Function to calculate time for a given distance
const calculateTimeForDistance = (distance, paceInKm) => {
    if (!paceInKm || !paceInKm.includes(':')) return '';  // Handle empty or invalid inputs

    const paceArray = paceInKm.split(':');
    if (paceArray.length !== 2) return '';  // Ensure we have exactly two parts: minutes and seconds

    const paceMinutes = parseInt(paceArray[0]);
    const paceSeconds = parseInt(paceArray[1]);

    // Check if the parsing works correctly
    if (isNaN(paceMinutes) || isNaN(paceSeconds)) return '';

    const paceInMinutes = paceMinutes + paceSeconds / 60;

    // Calculate time for the distance in minutes
    const totalTimeInMinutes = paceInMinutes * distance;
    const hours = Math.floor(totalTimeInMinutes / 60);
    const minutes = Math.floor(totalTimeInMinutes % 60);
    const seconds = Math.round((totalTimeInMinutes % 1) * 60);

    // Return the formatted time (hh:mm:ss)
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const formatPace = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs.toString().padStart(2, "0")}`;
}


function Pace() {
    const [pace, setPace] = useState(360);
    const [customDistance, setCustomDistance] = useState('');

    const paceInKm = formatPace(pace);
    const paceInMiles = convertPaceToMile(paceInKm) || 'Invalid';
    const kph = calculateKph(paceInKm) || 'Invalid';
    const mph = calculateMph(paceInKm) || 'Invalid';

    // Calculate the time for each distance based on the input pace
    const time400m = calculateTimeForDistance(0.4, paceInKm) || 'Invalid';
    const time1k = calculateTimeForDistance(1, paceInKm) || 'Invalid';
    const time1mile = calculateTimeForDistance(1.6, paceInKm) || 'Invalid';
    const time2mile = calculateTimeForDistance(3.2, paceInKm) || 'Invalid';
    const time5k = calculateTimeForDistance(5, paceInKm) || 'Invalid';
    const time10k = calculateTimeForDistance(10, paceInKm) || 'Invalid';
    const time15k = calculateTimeForDistance(15, paceInKm) || 'Invalid';
    const time10mile = calculateTimeForDistance(16.1, paceInKm) || 'Invalid';
    const time20k = calculateTimeForDistance(20, paceInKm) || 'Invalid';
    const timeHalfMarathon = calculateTimeForDistance(21.0975, paceInKm) || 'Invalid';
    const time30k = calculateTimeForDistance(30, paceInKm) || 'Invalid';
    const timeMarathon = calculateTimeForDistance(42.195, paceInKm) || 'Invalid';
    const timeCustom = calculateTimeForDistance(customDistance, paceInKm) || 'Invalid';

    return (
        <div>
            <a href="/">Back</a>
            <h1>Pace</h1>
            <ul>
                <li>{paceInKm} / km <span style={{color: 'gray'}}>or {paceInMiles} / miles</span></li>
                <li>{kph} / kph <span style={{color: 'gray'}}>or {mph} / mph</span></li>
            </ul>
            <div>
                <input
                    type="range"
                    value={pace}
                    min={120}
                    max={600}
                    onChange={(e) => setPace(e.target.value)}
                    style={{width: 320}}
                />
            </div>
            <br/>
            <table border="0">
                <tr>
                    <th style={{textAlign: 'left'}}>Distance</th>
                    <th style={{textAlign: 'left'}}>Time</th>
                </tr>
                <tr>
                    <td><input
                        type="text"
                        value={customDistance}
                        style={{width: 30}}
                        onChange={(e) => setCustomDistance(e.target.value)}
                    /> k</td>
                    <td>{timeCustom}</td>
                </tr>
                <tr>
                    <td>5k</td>
                    <td>{time5k}</td>
                </tr>
                <tr>
                    <td>10k</td>
                    <td>{time10k}</td>
                </tr>
                <tr>
                    <td>Half Marathon</td>
                    <td>{timeHalfMarathon}</td>
                </tr>
                <tr>
                    <td>Marathon</td>
                    <td>{timeMarathon}</td>
                </tr>
                <tr>
                    <td>400m</td>
                    <td>{time400m}</td>
                </tr>
                <tr>
                    <td>1k</td>
                    <td>{time1k}</td>
                </tr>
                <tr>
                    <td>1 mile</td>
                    <td>{time1mile}</td>
                </tr>
                <tr>
                    <td>2 mile</td>
                    <td>{time2mile}</td>
                </tr>
                <tr>
                    <td>15k</td>
                    <td>{time15k}</td>
                </tr>
                <tr>
                    <td>10 mile</td>
                    <td>{time10mile}</td>
                </tr>
                <tr>
                    <td>20k</td>
                    <td>{time20k}</td>
                </tr>
                <tr>
                    <td>30k</td>
                    <td>{time30k}</td>
                </tr>
            </table>
        </div>
    );
}

export default Pace;
